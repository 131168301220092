export const order_invoice_form_spec =
{
    shipper_name: {
        caption: "Наименование отправителя",
        name: "shipper_name",
        required: true,
        rules: [
            v => !!v || 'Укажите наименование',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },

    shipper_email: {
        caption: "E-mail отправителя",
        name: "shipper_email",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Добавьте email',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },

    shipper_phone: {
        caption: "Телефон отправителя",
        name: "shipper_phone",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите телефон',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },

    shipper_fio: {
        caption: "ФИО отправителя",
        name: "shipper_fio",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите ФИО отправителя',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },

    shipper_address: {
        caption: "Адрес отправителя",
        name: "shipper_address",
        required: true,
        editable: true,
        rules: [
            v => !!v || 'Укажите адрес',
            value => {
                const pattern = /[`']/;
                return !pattern.test(value) || "Присутствуют запрещённые символы"
            },
        ],
    },

    invoice_number: {
        caption: "Инвойс",
        name: "invoice_number",
        required: true,
        editable: false,
        rules: [
            v => !!v || 'Укажите номер',
            // v => /^\d+$/.test(v) || 'Инвойс должен содержать только цифры',
        ],
    },
}
